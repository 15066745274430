<template>
    <div class="card">
        <Toast />
        <h5>{{tituloPagina}} Usuários</h5>

        <div class="col-12 sm:col-12 lg:col-8 border-panel">
            <div class="grid">
                <div class="col-8 sm:col-9 lg:col-4">
                    <label for="matricula"><strong>Matrícula</strong></label>
                    <InputText
                        v-if="matricula"
                        disabled="true"
                        class="w-full mt-1"
                        id="usuario"
                        v-model="usuario.usuario.matricula" />
                    
                    <AutoComplete
                        v-if="!matricula" 
                        class="w-full mt-1"
                        id="usuario"
                        v-model="matriculaSelecionada"
                        field="usuario.matricula"
                        :suggestions="matriculas"
                        @complete="buscarMatriculasDisponiveis($event)" 
                        @item-select="selecionarMatricula"
                        dropdown />
                    
                    <InputText 
                        v-if="!matricula" 
                        hidden="true" 
                        id="usuario"
                        v-model="usuario.usuario.matricula" />
                </div>
                <div class="col-8 sm:col-9 lg:col-4">
                    <label for="estabelecimento"><strong>Estabelecimento</strong></label>
                    <InputText
                        disabled="true" 
                        :class="['w-full', 'mt-1', {'p-invalid': estabelecimentoInvalido}]"
                        id="estabelecimento"
                        v-model="usuario.estabelecimento.codigo" />
                </div>
                <div class="break-flex-row"></div>
                <div class="col-8 sm:col-9 lg:col-8">
                    <label for="nome"><strong>Nome</strong></label>
                    <InputText
                        disabled="true"
                        :class="['w-full', 'mt-1', {'p-invalid': nomeInvalido}]"
                        id="nome"
                        v-model="usuario.usuario.nome" />
                </div>
                <div class="break-flex-row"></div>
                <div class="col-8 sm:col-9 lg:col-4">
                    <label for="tipoUsuarioPortal"><strong>Tipo</strong></label>
                    <Dropdown class="w-full mt-1" 
                        id="tipoUsuarioPortal" 
                        v-model="tipoUsuarioPortal" 
                        :options="tipoUsuarioPortalOpcoes" 
                        optionLabel="nome" 
                        v-if="ehAdministrador" />
                    <InputText
                        disabled="true"
                        :class="['w-full', 'mt-1']"
                        id="tipoUsuarioPortal"
                        v-model="tipoUsuarioPortal.valor"
                        v-if="!ehAdministrador" />
                </div>
                <div class="col-8 sm:col-9 lg:col-4">
                    <label for="ativo"><strong>Ativo</strong></label>
                    <Dropdown class="w-full mt-1" 
                        id="ativo" 
                        v-model="ativo" 
                        :options="ativoOpcoes" 
                        optionLabel="nome" />
                </div>
                <div class="break-flex-row"></div>
                <div class="col-8 sm:col-9 lg:col-4">
                    <label for="enviaMensagem"><strong>Enviar mensagem</strong></label>
                    <Dropdown class="w-full mt-1" 
                        id="enviaMensagem" 
                        v-model="enviaMensagem" 
                        :options="enviaMensagemOpcoes" 
                        optionLabel="nome" />
                </div>
                <div class="col-8 sm:col-9 lg:col-4">
                    <label for="recebeNotificacaoPendencias"><strong>Receber notificação</strong></label>
                    <Dropdown class="w-full mt-1" 
                        id="recebeNotificacaoPendencias" 
                        v-model="recebeNotificacaoPendencias" 
                        :options="recebeNotificacaoPendenciasOpcoes" 
                        optionLabel="nome" />
                </div>
            </div>
            <br />

            <div class="flex align-items-center">
                <Button label="Cancelar" icon="pi pi-times" class="p-button p-component p-button-danger mr-2" @click="dialog = true;" />
                <Button label="Salvar" icon="pi pi-save" class="p-button p-component p-button-success" @click="salvar()" />
            </div>
        </div>
    </div>

    <Dialog v-model:visible="dialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <span>Tem certeza de que deseja cancelar?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="dialog = false;" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="voltar()" />
        </template>
    </Dialog>
</template>

<script>
    import UsuarioService from '../../service/UsuarioService';
    import Formatacao from '../../utilities/Formatacao';
    import StorageService from '../../service/StorageService';

    export default {
        name: 'Usuarios',
        props: {
            matricula: {
                type: String,
                required: false
            },
        },
        data() {
            return {
                tituloPagina: this.matricula ? 'Alterar' : 'Cadastrar',
                usuario: {
                    estabelecimento: {},
                    usuario: {}
                },
                dialog: false,

                matriculaSelecionada: null,
                matriculas: [],

                tipoUsuarioPortal: {nome: 'Regional', valor: 'REGIONAL'},
                tipoUsuarioPortalOpcoes: [
                    {nome: 'Administrador', valor: 'ADMIN'},
                    {nome: 'Regional', valor: 'REGIONAL'}
                ],
                ativo: {nome: 'Sim', valor: true},
                ativoOpcoes: [
                    {nome: 'Sim', valor: true},
                    {nome: 'Não', valor: false}
                ],
                enviaMensagem: {nome: 'Não', valor: false},
                enviaMensagemOpcoes: [
                    {nome: 'Sim', valor: true},
                    {nome: 'Não', valor: false}
                ],
                recebeNotificacaoPendencias: {nome: 'Não', valor: false},
                recebeNotificacaoPendenciasOpcoes: [
                    {nome: 'Sim', valor: true},
                    {nome: 'Não', valor: false}
                ],

                matriculaInvalida: false,
                estabelecimentoInvalido: false,
                nomeInvalido: false,

                toastTipoErro: 'error',
                toastTituloFalha: 'Falha',
                toastTipoSucesso: 'success',
                toastTituloSucesso: 'Sucesso',
                toastLife: 3000,
                toastDetalheAtuazlizacaoSucesso: 'O usuário foi atualizado com sucesso!',
                toastDetalheAtualizacaoFalha: 'Falha ao atualizar o usuário!',
                toastDetalheCadastradoSucesso: 'O usuário foi cadastrado com sucesso!',
                toastDetalheCadastroFalha: 'Falha ao cadastrar o usuário!',
                toastDetalheUsuarioNaoEncontrado: 'Matrícula não encontrada!',
                ehAdministrador: this.getAdmin().administrador,
            }
        },
        created() {
            this.consultarUsuario();
        },
        methods: {
            consultarUsuario() {
                if (this.ehCadastro()) return;

                UsuarioService.getUsuario(this.matricula)
                    .then(({ data }) => {
                        if (data) {
                            this.usuario = data;

                            this.matriculaSelecionada = data.usuario.matricula;

                            this.tipoUsuarioPortal = this.tipoUsuarioPortalOpcoes.find(item => {
                                return item.valor.toUpperCase() === data.tipoUsuarioPortal.toUpperCase();
                            });

                            this.enviaMensagem = this.enviaMensagemOpcoes.find(item => {
                                return item.valor === data.enviaMensagem
                            });

                            this.recebeNotificacaoPendencias = this.recebeNotificacaoPendenciasOpcoes.find(item => {
                                return item.valor === data.recebeNotificacaoPendencias
                            });

                            this.ativo = this.ativoOpcoes.find(item => {
                                return item.valor === data.ativo
                            });
                        } else {
                            this.voltar();
                        }
                    })
                    .catch(error => {
                        this.voltar();
                    });
            },
            ehCadastro() {
                return !this.matricula;
            },
            voltar() {
                this.$router.push(`/usuarios`);
            },
            validarCampos() {
                let isValid = true;

                if (!this.usuario.usuario.matricula) {
                    this.matriculaInvalida = true;
                    isValid = false;
                }

                if (!this.usuario.estabelecimento.codigo) {
                    this.estabelecimentoInvalido = true;
                    isValid = false;
                }

                if (!this.usuario.usuario.nome) {
                    this.nomeInvalido = true;
                    isValid = false;
                }

                if (!isValid) {
                    this.abrirToast(this.toastTipoErro, this.toastTituloFalha, 'Preencher os campos obrigatórios.', this.toastLife);
                    return;
                }

                return isValid;
            },
            buscarMatriculasDisponiveis(event) {
                let matricula = !event.query.trim().length ? '1' : event.query;
                UsuarioService.buscarMatriculasDisponiveis(matricula)
                    .then(({data}) => {
                        if (data) {
                            this.matriculas = data;
                        }
                    })
                    .catch(error => {
                        this.matriculas = [];
                    });

            },
            salvar() {
                if(!this.validarCampos()) return;
                this.alterar();
            },
            alterar() {
                let usuario = this.obterUsuario();
                
                UsuarioService.alterar(usuario)
                    .then(response => {
                        this.abrirToast(this.toastTipoSucesso, this.toastTituloSucesso, this.toastDetalheAtuazlizacaoSucesso, this.toastLife);

                        setTimeout(() => {
                            this.voltar();
                        }, "1000");
                    })
                    .catch(error => {
                        this.abrirToast(this.toastTipoErro, this.toastTituloFalha, this.toastDetalheAtualizacaoFalha, this.toastLife);
                    });
            },
            obterUsuario() {
                let usuario = this.usuario;
                usuario['tipoUsuarioPortal'] = this.tipoUsuarioPortal?.valor;
                usuario['ativo'] = this.ativo?.valor;
                usuario['enviaMensagem'] = this.enviaMensagem?.valor;
                usuario['recebeNotificacaoPendencias'] = this.recebeNotificacaoPendencias?.valor;

                return usuario;
            },
            abrirToast(tipo, titulo, descricao, tempo) {
                this.$toast.add({
                    severity: tipo, 
                    summary: titulo, 
                    detail: descricao, 
                    life: tempo
                });
            },
            formatDate(data) {
                return Formatacao.formatDate(data);
            },
            selecionarMatricula() {
                if (!this.matriculaSelecionada) return;

                this.usuario.usuario = this.matriculaSelecionada.usuario;
                this.usuario.estabelecimento.codigo = this.matriculaSelecionada.estabelecimento.codigo;
            },
            getAdmin() {
                return StorageService.getAdmin();
            }
        },
    }
</script>

<style>
    .break-flex-row {
        flex-basis: 100%;
        height: 0;
    }
    input.readonly {
        background-color: aquamarine;
    }
</style>
