<template>
    <Usuario v-if="!isCadastrarOuEditar()" />
    <UsuarioForm v-if="isCadastrarOuEditar()" :matricula="matricula" />
</template>

<script lang="js">
    import Usuario from '../../components/cadastro/Usuario.vue'
    import UsuarioForm from '../../components/cadastro/UsuarioForm.vue';

    export default {
        name: 'Usuarios',
        components: { Usuario, UsuarioForm },
        data() {
            return {
                isCadastrar: false,
                isAlterar: false,
                matricula: null,
            };
        },
        created() {
            this.validarRotas();
        },
        updated() {
            this.validarRotas();
        },
        methods: {
            validarRotas() {
                let nomeRota = this.$route.name;
                if (nomeRota === 'cadastrarUsuario') {
                    this.isCadastrar = true;
                } else if (nomeRota === 'alterarUsuario') {
                    this.isAlterar = true;
                    this.matricula = this.$route.params.matricula;
                } else {
                    this.isCadastrar = false;
                    this.isAlterar = false;
                    this.matricula = null;
                }
            },

            isCadastrarOuEditar() {
                return this.isCadastrar || this.isAlterar;
            },
        },
    }

</script>
